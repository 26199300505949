"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class PdfGenerator {
    constructor(button, attr) {
        this.button = button;
        this.attr = attr;
        this.isSingle = attr === 'single' ? true : false;
        this.pageId = document.body.getAttribute('data-js-page-id');
        this.postType = document.body.getAttribute('data-js-post-type');
        const apiRoot = wpApiSettings.root;
        if (apiRoot) {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                this.isSingle ? this.fetchPdfForSingle(apiRoot) : this.fetchPdfForArchive(apiRoot);
            });
        }
    }
    fetchPdfForArchive(apiRoot) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryString = window.location.search;
            const searchParams = new URLSearchParams(queryString);
            if (this.postType) {
                searchParams.delete('paged');
                window.location.href = apiRoot + 'pdf/v1/' + this.postType + '/' + (searchParams.toString() ? '?' + searchParams.toString() : '');
            }
        });
    }
    fetchPdfForSingle(apiRoot) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.pageId) {
                window.location.href = apiRoot + 'pdf/v1/id=' + this.pageId;
            }
        });
    }
}
document.addEventListener("DOMContentLoaded", () => {
    const pdfGeneratorButtons = document.querySelectorAll('[data-js-pdf-generator]');
    [...pdfGeneratorButtons].forEach(button => {
        const attr = button.getAttribute('data-js-pdf-generator');
        if (attr) {
            new PdfGenerator(button, attr);
        }
    });
});
